import React, { createContext, useState } from "react";

export const GlobalEventCacheContext = createContext();

export const GlobalEventCacheProvider = ({ children }) => {
  const [globalEventCache, setGlobalEventCache] = useState({
    idMap: {},
    dateMap: {},
  });

  return (
    <GlobalEventCacheContext.Provider
      value={{ globalEventCache, setGlobalEventCache }}
    >
      {children}
    </GlobalEventCacheContext.Provider>
  );
};
