/* global gapi */
import React, { useRef, useEffect, useState, useContext } from "react";
import { GlobalEventCacheContext } from "./GlobalEventCacheProvider";

const getMonthName = (monthIndex) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[monthIndex];
};

const getMonthColors = (monthIndex) => {
  const monthColors = [
    { primary: "#EA9999", secondary: "#F3CCCB" },
    { primary: "#F9CB9C", secondary: "#FCE5CD" },
    { primary: "#A0C5E8", secondary: "#D0E2F3" },
    { primary: "#B7D7A8", secondary: "#D9EAD3" },
    { primary: "#B4A7D7", secondary: "#D9D3E9" },
    { primary: "#F1A8C6", secondary: "#EFDDE6" },
    { primary: "#EB9999", secondary: "#F5CBCC" },
    { primary: "#FACA9C", secondary: "#FCE5CD" },
    { primary: "#9EC5E7", secondary: "#D0E2F4" },
    { primary: "#B7D7A8", secondary: "#D9EAD3" },
    { primary: "#B4A7D7", secondary: "#D9D2E9" },
    { primary: "#F1A8C6", secondary: "#EFDDE6" },
  ];

  return monthColors[monthIndex];
};

function getDaysInMonth(month, year) {
  // Month in JavaScript is 0-indexed (0 for January, 1 for February, etc.), so we add 1
  return new Date(year, month + 1, 0).getDate();
}

function getWeekday(year, month, day) {
  // Month in JavaScript is 0-indexed (0 for January, 1 for February, etc.)
  const date = new Date(year, month, day);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekdays[date.getDay()];
}

const handleInputChange = (event, dateStr, calendarId, globalEventCache) => {
  const title = event.target.value;
  const eventId =
    event &&
    event.target &&
    event.target.attributes &&
    event.target.attributes["data-event-id"];
  if (title) {
    // Create an event on this date with the given title
    if (eventId) {
      modifyGoogleCalendarEvent(eventId, title, calendarId, globalEventCache);
    } else {
      createGoogleCalendarEvent(dateStr, title, calendarId);
    }
  } else {
    if (eventId) {
      deleteGoogleCalendarEvent(eventId, calendarId);
    }
  }
};

const createGoogleCalendarEvent = (dateStr, title, calendarId) => {
  const eventDate = new Date(dateStr);
  const event = {
    summary: title,
    start: {
      date: eventDate.toISOString().split("T")[0], // Formats the date as 'YYYY-MM-DD'
    },
    end: {
      date: new Date(eventDate.getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0], // The day after the eventDate
    },
  };

  // Call the Google Calendar API to create the event
  gapi.client.calendar.events
    .insert({
      calendarId: calendarId,
      resource: event,
    })
    .then((response) => {
      console.log("Event created: ", response);
    })
    .catch((error) => {
      console.error("Error creating event: ", error);
    });
};

const deleteGoogleCalendarEvent = (eventId, calendarId) => {
  if (!eventId || !calendarId) {
    console.error("Missing eventId or calendarId");
    return;
  }

  gapi.client.calendar.events
    .delete({
      calendarId: calendarId,
      eventId: eventId,
    })
    .then((response) => {
      // Handle successful deletion
      console.log("Event deleted", response);
    })
    .catch((error) => {
      // Handle error in deletion
      console.error("Error deleting event", error);
    });
};

const modifyGoogleCalendarEvent = (
  eventId,
  title,
  calendarId,
  globalEventCache
) => {
  if (!eventId || !calendarId || !title) {
    console.error("Missing eventId, calendarId, or title");
    return;
  }

  const cachedEvent = globalEventCache.idMap[eventId];

  const event = {
    ...cachedEvent,
    summary: title,
  };

  gapi.client.calendar.events
    .update({
      calendarId: calendarId,
      eventId: eventId,
      resource: event,
    })
    .then((response) => {
      // Handle successful update
      console.log("Event updated: ", response);
    })
    .catch((error) => {
      // Handle error in update
      console.error("Error updating event: ", error);
    });
};

const MonthGrid = ({
  monthIndex,
  globalSelectionRange,
  setGlobalSelectionRange,
}) => {
  const calendarId =
    "c145f2a8f2c2055ab2a7b2a146cc9fb684fd69c741dda2b3b280ec944e1d5407@group.calendar.google.com";
  const year = new Date().getFullYear();
  const monthName = getMonthName(monthIndex);
  const { primary: backgroundColor, secondary: weekendColor } =
    getMonthColors(monthIndex);
  const daysInMonth = getDaysInMonth(monthIndex, year);
  const inputRefs = useRef([]);

  const { globalEventCache, setGlobalEventCache } = useContext(
    GlobalEventCacheContext
  );

  useEffect(() => {
    inputRefs.current = Array.from({ length: daysInMonth }, () => null);
  }, [daysInMonth]);

  const assignInputRef = (element, day) => {
    inputRefs.current[day - 1] = element;
  };

  // Create an array with 31 days and map over it to render grid items
  const days = Array.from({ length: daysInMonth }, (_, index) => index + 1);

  const [isSelectingRange, setIsSelectingRange] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(Array(daysInMonth).fill(true));
  const [selectionRange, setSelectionRange] = useState({
    start: null,
    end: null,
  });

  const handleSelectStart = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    return () => {
      // Clean up global event listener
      document.removeEventListener("selectstart", handleSelectStart);
    };
  }, []);

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isSelectingRange) {
        setIsSelectingRange(false);
      }
    };

    document.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleGlobalMouseUp);
      document.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isSelectingRange]);

  const handleMouseDown = (day) => {
    if (globalSelectionRange.monthIndex !== monthIndex) {
      setGlobalSelectionRange({ start: day, end: day, monthIndex });
    } else {
      // Continue selection within the same month
      setGlobalSelectionRange({
        ...globalSelectionRange,
        start: day,
        end: day,
      });
    }
    setIsSelectingRange(true);
    document.addEventListener("selectstart", handleSelectStart);
  };

  const handleMouseOver = (day) => {
    if (isSelectingRange) {
      console.log("Mouse over day", day); // Debug log
      setGlobalSelectionRange((prev) => ({ ...prev, end: day }));
    }
  };

  const handleMouseUp = () => {
    // Handle the end of the selection here
    // e.g., process the selected range or reset
    setIsSelectingRange(false);
  };

  const isDaySelected = (day) => {
    if (globalSelectionRange.monthIndex !== monthIndex) return false;
    const { start, end } = globalSelectionRange;
    return start && end && day >= start && day <= end;
  };

  const isFirstDaySelected = (day) => {
    if (globalSelectionRange.monthIndex !== monthIndex) return false;
    const { start } = globalSelectionRange;
    return day === start;
  };

  const isLastDaySelected = (day) => {
    if (globalSelectionRange.monthIndex !== monthIndex) return false;
    const { end } = globalSelectionRange;
    return day === end;
  };

  const handleContainerClick = (dayIndex) => {
    const updatedReadOnly = Array(daysInMonth).fill(true);
    updatedReadOnly[dayIndex] = false; // Make the input field for this day editable
    setIsReadOnly(updatedReadOnly);
    // setTimeout(() => {
    //     inputRefs.current[dayIndex]?.focus();
    //   }, 1000);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.target.blur(); // This will remove focus from the input
    }
  };

  return (
    <div className="month">
      <div className="grid-container">
        <div
          className="grid-item month-header"
          style={{ gridColumn: "1 / -1", backgroundColor: backgroundColor }}
        >
          {monthName}
        </div>
        {days.map((day) => {
          const weekday = getWeekday(year, monthIndex, day);
          const isWeekend = weekday === "Saturday" || weekday === "Sunday";
          const weekend = isWeekend ? "weekend" : "";
          const dateClass = `date-${year}-${monthIndex + 1}-${day}`;
          const cellColor = weekend ? weekendColor : "";

          const date = new Date();
          const isToday =
            date.getFullYear() === year &&
            date.getMonth() === monthIndex &&
            date.getDate() === day;
          const currentDayLeft = isToday ? "<" : "";
          const currentDayRight = isToday ? ">" : "";
          return (
            <React.Fragment key={day}>
              <div
                style={{
                  backgroundColor: cellColor,
                  fontWeight: isToday ? "bold" : "",
                }}
                className={`grid-item calendar-number ${weekend} ${dateClass}`}
              >
                {currentDayLeft}
                {day}
                {currentDayRight}
              </div>
              <div
                onDoubleClick={() => handleContainerClick(day - 1)}
                style={{
                  backgroundColor: cellColor,
                  fontWeight: isToday ? "bold" : "",
                }}
                className={`grid-item calendar-entry ${weekend} ${dateClass} ${
                  isDaySelected(day) ? "selected" : ""
                } ${isFirstDaySelected(day) ? "first-selected" : ""} ${
                  isLastDaySelected(day) ? "last-selected" : ""
                }`}
                onMouseDown={() => handleMouseDown(day)}
                onMouseOver={() => handleMouseOver(day)}
                onMouseUp={handleMouseUp}
              >
                <input
                  style={{ fontWeight: isToday ? "bold" : "" }}
                  type="text"
                  ref={(el) => assignInputRef(el, day)}
                  readOnly={isReadOnly[day - 1]}
                  onBlur={(e) => {
                    handleInputChange(
                      e,
                      `${year}-${monthIndex + 1}-${day}`,
                      calendarId,
                      globalEventCache
                    );
                    setGlobalSelectionRange({});
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MonthGrid;
