import React, { useRef, useEffect, useState, useContext } from "react";
import logo from "./logo.svg";
import "./App.css";
import { gapi } from "gapi-script";
import MonthGrid from "./MonthGrid";
import {
  GlobalEventCacheProvider,
  GlobalEventCacheContext,
} from "./GlobalEventCacheProvider";
import rough from "roughjs";
import {
  Overlay,
  Button,
  OverlayTrigger,
  Tooltip,
  Popover,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";

const CLIENT_ID =
  "991363453122-79d9f89191hlb391uncf497ch1srq1ci.apps.googleusercontent.com";
const API_KEY = "AIzaSyCshL6m_DdgM06WIiOqE8vK6IOis8-gdSo";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const SCOPES = "https://www.googleapis.com/auth/calendar";

const palette = [
  "#38761d", // Deep green
  "#cc0000", // Rich red
  "#bf9000", // Vivid gold
  "#a64d79", // Strong magenta
  "#c27ba0", // Rich pink
  "#a61c00", // Burnt orange
  "#274e13", // Forest green
  "#0b5394", // Deep sky blue
  "#351c75", // Dark purple
  "#1155cc", // Bold blue
  "#e69138", // Vibrant orange
  "#134f5c", // Dark teal
];

const firebaseConfig = {
  apiKey: "AIzaSyCycjeVyiSkNT4OS1mtKdVMBjR8QE2CXKA",
  authDomain: "year-at-a-glance-a89d2.firebaseapp.com",
  projectId: "year-at-a-glance-a89d2",
  storageBucket: "year-at-a-glance-a89d2.appspot.com",
  messagingSenderId: "495262921080",
  appId: "1:495262921080:web:c3f2b6e92487a1937c988d",
  measurementId: "G-HEP2YNZKBH",
};

function App() {
  const [globalSelectionRange, setGlobalSelectionRange] = useState({
    start: null,
    end: null,
    monthIndex: null,
  });
  const { globalEventCache, setGlobalEventCache } = useContext(
    GlobalEventCacheContext
  );
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [userEmail, setUserEmail] = useState(null);
  const [calendarList, setCalendarList] = useState(null);
  const [selectedCalendar, setSelectedCalendar] = useState({
    summary: "Select a calendar",
  });

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const log = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
  };

  useEffect(() => {
    // Load the Google API client library
    gapi.load("client:auth2", initClient);
  }, []);

  // useEffect hook to call updateSigninStatus when selectedCalendar changes
  useEffect(() => {
    // Call updateSigninStatus only if a calendar has been selected
    if (selectedCalendar.id) {
      log("setting calendar", { calendar: selectedCalendar });
      clearGridAndCanvas();
      updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    }
  }, [selectedCalendar]); // Dependency array with selectedCalendar

  useEffect(() => {
    if (selectedCalendar && selectedCalendar.id) {
      // Serialize and save to local storage
      localStorage.setItem(
        "selectedCalendar",
        JSON.stringify(selectedCalendar)
      );
    }
  }, [selectedCalendar]);

  useEffect(() => {
    const container = document.getElementById("monthContainer");
    const canvas = document.getElementById("canvas");
    const rc = rough.canvas(canvas);

    if (container && canvas) {
      // Assuming each month is 304px wide and there are 12 months
      const totalWidth = 304 * 12;
      const totalHeight = container.offsetHeight;

      // Get the device pixel ratio, falling back to 1.
      const dpr = 1; //window.devicePixelRatio || 1;

      canvas.width = totalWidth * dpr;
      canvas.height = totalHeight * dpr;

      // Set the CSS dimensions of the canvas
      canvas.style.width = `${totalWidth * dpr}px`;
      canvas.style.height = `${totalHeight * dpr}px`;
      rc.ctx.scale(dpr, dpr);

      // // Get the device pixel ratio, falling back to 1.
      // const dpr = window.devicePixelRatio || 1;

      // // Set the 'width' and 'height' of canvas
      // canvas.width = container.offsetWidth * dpr;
      // canvas.height = container.offsetHeight * dpr;

      // Iterate over events in globalEventCache to find multi-day events
      Object.values(globalEventCache.idMap).forEach((event) => {
        // Assuming each event object has a start and end date
        const startDate = new Date(event.start.date);
        const endDate = new Date(event.end.date);
        endDate.setDate(endDate.getDate() - 1);

        const timeDiff = endDate - startDate;

        const highlightColor =
          palette[getDayOfYear(startDate) % palette.length];

        if (
          startDate.getMonth() === endDate.getMonth() &&
          timeDiff > 86400000
        ) {
          // Event spans multiple days in the same month
          const startCellPosition = getCellPosition(
            startDate.getUTCFullYear(),
            startDate.getUTCDate(),
            startDate.getUTCMonth()
          );
          const endCellPosition = getCellPosition(
            endDate.getUTCFullYear(),
            endDate.getUTCDate(),
            endDate.getUTCMonth()
          );

          const strokeWidth = 2;
          const strokeRoughness = 1;

          const leftOffset = startDate.getMonth() * 304;
          if (startCellPosition && endCellPosition) {
            const startX = 50 + leftOffset; // Adjust as needed
            const startY = startCellPosition.top;
            const endY = endCellPosition.bottom;

            rc.line(startX / dpr, startY / dpr, startX / dpr, endY / dpr, {
              stroke: `${highlightColor}aa`,
              strokeWidth: strokeWidth,
              roughness: strokeRoughness,
            });

            // Arrowhead size
            const arrowheadLength = 7; // Length of each side of the arrowhead

            // Coordinates for the top arrowhead
            const topArrowheadLeftX = startX - arrowheadLength;
            const topArrowheadRightX = startX + arrowheadLength;
            const topArrowheadY = startY;

            // Draw left part of the top arrowhead
            rc.line(
              startX / dpr,
              startY / dpr,
              topArrowheadLeftX / dpr,
              topArrowheadY / dpr,
              {
                stroke: `${highlightColor}aa`,
                strokeWidth: strokeWidth,
                roughness: strokeRoughness,
              }
            );

            // Draw right part of the top arrowhead
            rc.line(
              startX / dpr,
              startY / dpr,
              topArrowheadRightX / dpr,
              topArrowheadY / dpr,
              {
                stroke: `${highlightColor}aa`,
                strokeWidth: strokeWidth,
                roughness: strokeRoughness,
              }
            );

            // Coordinates for the bottom arrowhead
            const bottomArrowheadLeftX = startX - arrowheadLength;
            const bottomArrowheadRightX = startX + arrowheadLength;
            const bottomArrowheadY = endY;

            // Draw left part of the bottom arrowhead
            rc.line(
              startX / dpr,
              endY / dpr,
              bottomArrowheadLeftX / dpr,
              bottomArrowheadY / dpr - arrowheadLength,
              {
                stroke: `${highlightColor}aa`,
                strokeWidth: strokeWidth,
                roughness: strokeRoughness,
              }
            );

            // Draw right part of the bottom arrowhead
            rc.line(
              startX / dpr,
              endY / dpr,
              bottomArrowheadRightX / dpr,
              bottomArrowheadY / dpr - arrowheadLength,
              {
                stroke: `${highlightColor}aa`,
                strokeWidth: strokeWidth,
                roughness: strokeRoughness,
              }
            );

            const dateClass = `${startDate.getUTCFullYear()}-${
              startDate.getUTCMonth() + 1
            }-${startDate.getUTCDate()}`;
            const inputElement = document.querySelector(
              `.date-${dateClass} input`
            );
            inputElement.style.color = highlightColor;
          }
        }
      });
    }
  }, [globalEventCache]);

  const initClient = () => {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        () => {
          // Initialization successful
          // Listen for sign-in state changes and handle them
          gapi.auth2.getAuthInstance().isSignedIn.listen(() => {
            updateSigninStatus();
          });

          // Retrieve the selected calendar from local storage
          const savedCalendar = localStorage.getItem("selectedCalendar");
          if (savedCalendar) {
            setSelectedCalendar(JSON.parse(savedCalendar));
          }

          // Handle the initial sign-in state
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        (error) => {
          console.error("Error initializing Google API client", error);
        }
      );
  };

  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      const profile = gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getBasicProfile();
      const email = profile.getEmail();

      // Save the email address to state
      setUserEmail(email);

      listCalendars();

      console.log("Signed in already");
      log("authenticated launch", { email: email });
      fetchYearInProgressEvents();
      // User is signed in, you can list calendars or perform other API calls
    } else {
      log("unauthenticated launch");
      console.log("Not signed in");
      // User is not signed in, prompt them to sign in
    }
  };

  const listCalendars = async () => {
    try {
      const response = await gapi.client.calendar.calendarList.list();
      console.log("Calendars:", response.result.items);
      setCalendarList(response.result.items);
      // Process calendars here
    } catch (error) {
      console.error("Error fetching calendars", error);
    }
  };

  const fetchEvents = async (calendarId) => {
    try {
      const now = new Date();
      // You can customize these parameters to fit your needs
      const response = await gapi.client.calendar.events.list({
        calendarId: calendarId,
        timeMin: new Date(`${now.getFullYear()}-01-01`).toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 100,
        orderBy: "startTime",
      });

      const events = response.result.items;
      console.log(events);
      return events;
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleGoogleLogin = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn({
        prompt: "consent", // This will force the account selection and consent screen
      })
      .then(() => {
        const profile = gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getBasicProfile();
        const email = profile.getEmail();

        // Save the email address to state
        setUserEmail(email);

        // After signing in, you can call your listCalendars function
        listCalendars();
      });
  };

  const handleGoogleLogout = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      console.log("User signed out.");
      // Additional actions post-logout like resetting user state
      setUserEmail(null);
      setSelectedCalendar({ summary: "Select a calendar" });
      setCalendarList(null);
      localStorage.removeItem("selectedCalendar");
      clearGridAndCanvas();
    });
  };

  const fetchYearInProgressEvents = async () => {
    const calendarId = selectedCalendar.id;
    if (!calendarId) return;
    const events = await fetchEvents(calendarId);
    const dateMap = {};
    const idMap = {};
    events.forEach((event) => {
      const startDate = new Date(event.start.date);
      const endDate = new Date(event.end.date);
      const dateClass = `${startDate.getUTCFullYear()}-${
        startDate.getUTCMonth() + 1
      }-${startDate.getUTCDate()}`;
      const inputElement = document.querySelector(`.date-${dateClass} input`);
      if (inputElement) {
        inputElement.value = event.summary;
        inputElement.attributes["data-event-id"] = event.id;
        dateMap[dateClass] = event;
        idMap[event.id] = event;
      }
    });
    setGlobalEventCache({ dateMap, idMap });
  };

  const months = Array.from({ length: 12 }, (_, index) => index);

  // <button onClick={handleAuthClick}>Sign In with Google</button>
  // <button onClick={fetchYearInProgressEvents}>List Events</button>

  const getCellPosition = (year, day, monthIndex) => {
    const cell = document.querySelector(
      `.date-${year}-${monthIndex + 1}-${day}`
    );
    if (cell) {
      const rect = cell.getBoundingClientRect();
      return { top: rect.top, bottom: rect.bottom };
    }
    return null;
  };

  function getDayOfYear(date) {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
  }

  const handleSelectCalendar = (eventKey) => {
    const selectedCalendar = calendarList.find(
      (calendar) => calendar.id === eventKey
    );
    setSelectedCalendar(selectedCalendar); // Update the button title to the selected calendar's name
    // Do something with the selected calendar
    console.log(selectedCalendar);
  };

  function clearGridAndCanvas() {
    // Clear all input fields in the grid
    const inputs = document.querySelectorAll(".month-container input");
    inputs.forEach((input) => {
      input.value = "";
    });

    // Clear the canvas
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  return (
    <div className="App">
      <div className="navigation-bar">
        {/* Hamburger Icon Button */}

        <OverlayTrigger
          trigger="click"
          key="right"
          placement="right"
          rootClose
          overlay={
            <Popover id="overlay-example" className="popover-overlay">
              <Popover.Header as="h3">{`Settings`}</Popover.Header>
              <Popover.Body>
                {userEmail && (
                  <div className="user-info-container">
                    <div className="user-email">{userEmail}</div>
                    <DropdownButton
                      id="dropdown-calendar-select"
                      title={selectedCalendar.summary}
                      onSelect={handleSelectCalendar}
                      className="calendar-dropdown"
                    >
                      {" "}
                      {calendarList &&
                        calendarList.map((calendar) => (
                          <Dropdown.Item
                            key={calendar.id}
                            eventKey={calendar.id}
                          >
                            {calendar.summary}{" "}
                            {/* Assuming 'summary' is the name of the calendar */}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Button
                      onClick={handleGoogleLogout}
                      className="logout-button"
                      variant="danger"
                    >
                      Logout
                    </Button>
                  </div>
                )}
                {!userEmail && (
                  <Button onClick={handleGoogleLogin}>
                    Continue with Google
                  </Button>
                )}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="hamburger-icon">
            <img
              src={`${process.env.PUBLIC_URL}/logo192.png`}
              alt="Menu Icon"
            />
          </div>
        </OverlayTrigger>
        <h1 className="title">Year At A Glance</h1>
      </div>

      <canvas id="canvas"></canvas>
      <div className="month-container" id="monthContainer">
        {months.map((month) => {
          return (
            <React.Fragment key={month}>
              <MonthGrid
                key={month}
                monthIndex={month}
                globalSelectionRange={globalSelectionRange}
                setGlobalSelectionRange={setGlobalSelectionRange}
                globalEventCache={globalEventCache}
                setGlobalEventCache={setGlobalEventCache}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default App;
